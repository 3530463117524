import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from '../styles';
import { useIntl } from 'react-intl';
import Form from '@common/components/Form';
import { Button } from '@mui/material';
import SelectBox from '../../SelectBox';
import { PromotionImagePaginateList } from '../../../../../types';
import { fetchAllPromotionImageRequest } from '@/app/modules/ResourceLibrary/network/resourceCrud';

interface TypeProps {
  initialValue?: string;
  disabled?: boolean;
}

const PromotionImageFormItem = ({ initialValue, disabled }: TypeProps) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const selectRef: any = useRef(null);

  const [itemList, setItemList] = useState<PromotionImagePaginateList>({
    items: [],
    page: 1,
    limit: 20,
    totalRecords: 0,
  });
  const [itemSearchKeyword, setItemSearchKeyword] = useState<string>('');
  const [itemSelected, setItemSelected] = useState<any>(initialValue);

  useEffect(() => {
    getPromotionImageList(itemSearchKeyword);
  }, [itemSearchKeyword]);

  const getPromotionImageList = async (name?: string, page = 1, limit = 20) => {
    const payload = {
      name,
      page,
      limit,
    };

    const resultList = await fetchAllPromotionImageRequest(payload);
    if (page === 1) {
      setItemList(resultList);
    } else {
      setItemList((prev) => ({
        ...resultList,
        items: [...prev.items, ...resultList.items],
      }));
    }
  };

  const onLoadMoreItems = () => {
    const { page, limit, totalRecords } = itemList;

    if (page * limit < totalRecords) {
      getPromotionImageList(itemSearchKeyword, page + 1, limit);
    }
  };

  const unselectedItem = () => {
    setItemSelected(undefined);
    selectRef.current?.unselect();
  };

  return (
    <Form.Item
      required={false}
      childStyle={{ marginTop: 16, alignItems: 'flex-start' }}
      name="promotionImage"
      label={'Promotion Image'}
    >
      {({ value, onChange }) => {
        return (
          <div className={`${classes.d_flex_column} ${classes.w_50}`}>
            {itemSelected ? (
              <div className={`${classes.d_flex_center}`}>
                <span className={`${classes.mr_4}`}>{itemSelected.index || 0}</span>
                <img src={itemSelected.imgUrl} className={`${classes.imgBg} ${classes.mr_4}`} alt="template img" />
                <span className={`${classes.flex_fill}`}>{itemSelected.label}</span>
                <Button
                  disabled={disabled}
                  onClick={() => {
                    unselectedItem();
                    onChange(undefined);
                  }}
                  className={classes.downloadTxt}
                >
                  {Translation('app.button.unselect')}
                </Button>
              </div>
            ) : (
              <span>{Translation('resource.library.backgroundImagePlaceholder')}</span>
            )}
            <SelectBox
              disabled={disabled}
              ref={selectRef}
              options={itemList.items}
              onSelect={(item) => {
                setItemSelected(item);
                onChange(item);
              }}
              onLoadMore={() => onLoadMoreItems()}
              initialValue={initialValue}
              onChangeBgKeyword={setItemSearchKeyword}
              clientFilterDisabled
              previewType={2}
            />
          </div>
        );
      }}
    </Form.Item>
  );
};

export default React.memo(PromotionImageFormItem);
