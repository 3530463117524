import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import Select from 'react-select';
import { useStyle } from './styles';
import { Button, Dialog } from '@mui/material';
import { useIntl } from 'react-intl';

type ISelectProps = {
  options: any[];
  onSelect: (item: any) => void;
  onLoadMore: () => void;
  initialValue?: any;
  disabled?: boolean;
  onChangeBgKeyword: (txt: string) => void;
  clientFilterDisabled?: boolean;
  previewType?: number;
};

const SelectBox = forwardRef(
  (
    {
      options,
      onSelect,
      onLoadMore,
      initialValue,
      disabled,
      onChangeBgKeyword,
      clientFilterDisabled,
      previewType = 1,
    }: ISelectProps,
    ref: any,
  ) => {
    const { classes } = useStyle();
    const intl = useIntl();
    const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
    const [selectedItem, setSelectedItem] = useState(initialValue);
    const [isShowPreview, setShowPreview] = useState<any>(false);
    const [imagePreview, setImagePreview] = useState<any>();
    const optionRefs: any = useRef([]);

    useImperativeHandle(
      ref,
      () => {
        return {
          unselect: () => {
            setSelectedItem(undefined);
          },
        };
      },
      [],
    );
    return (
      <React.Fragment>
        <Select
          isDisabled={disabled}
          className={`${classes.mt_4}`}
          onMenuScrollToBottom={onLoadMore}
          onInputChange={onChangeBgKeyword}
          filterOption={() => true}
          onMenuClose={() => onChangeBgKeyword('')}
          components={{
            Option: ({ data, selectOption }) => (
              <div
                ref={(ref) => optionRefs.current.push(ref)}
                className={`${classes.d_flex_center} ${classes.itemStyle}`}
                onClick={(e) => {
                  setSelectedItem(data);
                  onSelect(data);
                  selectOption('');
                }}
              >
                <div className={`${classes.d_flex_center} ${classes.flex_fill}`}>
                  <span className={`${classes.indexTxt} ${classes.optionTxt}`}>{data.index}</span>
                  <img loading="lazy" src={data.imgUrl} className={classes.imgTemplate} alt="template img" />
                  <span className={classes.optionTxt}>{data.label}</span>
                </div>
                <div className={`${classes.d_flex_center}`}>
                  {!!selectedItem && selectedItem.id === data.id && (
                    <span className={classes.selectedTxt}>{Translation('app.common.selected')}</span>
                  )}
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setImagePreview(data.imgUrl);
                      setShowPreview(true);
                    }}
                    className={classes.viewTxt}
                  >
                    {Translation('app.button.view')}
                  </Button>
                </div>
              </div>
            ),
          }}
          closeMenuOnSelect={true}
          options={options}
          placeholder={Translation('resource.library.bg-image-select-placeholder')}
        />

        <Dialog
          onClose={() => {
            setShowPreview(false);
          }}
          open={previewType === 1 && isShowPreview}
          style={{ backgroundColor: 'transparent' }}
        >
          <img src={imagePreview} className={classes.previewImage} alt="template img" />
        </Dialog>
        <Dialog
          onClose={() => {
            setShowPreview(false);
          }}
          open={previewType === 2 && isShowPreview}
          style={{ backgroundColor: 'transparent' }}
        >
          <div className={classes.popupContent}>
            <img src={imagePreview} className={classes.popupImage} alt="template img" />
          </div>
        </Dialog>
      </React.Fragment>
    );
  },
);

export default React.memo(SelectBox);
