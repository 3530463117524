import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Form from '@common/components/Form';
import { FormInstance } from 'rc-field-form';
import { EVENT_TEMPLATE_CONFIG } from '../../../../../constants';
import { get } from 'lodash';
import Select, { components } from 'react-select';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';

const useStyle = makeStyles()((theme) => ({
  itemStyle: {
    borderBottomWidth: 0.5,
    borderColor: '#DFDFDF',
  },
  viewTxt: {
    color: '#4386E5',
    fontFamily: 'SVN-Poppins',
    fontSize: '1rem',
  },
  indexTxt: {
    width: 30,
  },
  imgTemplate: {
    marginRight: 10,
    width: 44,
    height: 44,
  },
  optionTxt: {
    color: '#333',
    fontFamily: 'SVN-Poppins',
    fontSize: 14,
    fontWeight: 400,
  },
  previewImage: {
    width: 900,
    height: 586,
  },
  selectedTxt: {
    color: '#ED1B2E',
    fontWeight: 'bold',
  },
  w_50: {
    width: '50%',
  },
  mt_4: {
    marginTop: '1rem',
  },
  d_flex_center: {
    display: 'flex',
    alignItems: 'center',
  },
  flex_fill: {
    flex: '1 1 auto',
  },
  contentItem: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    cursor: 'pointer',
  },
  d_flex_column: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface TypeProps {
  form: FormInstance<any>;
  unselectBgImage: () => void;
  getBackgroundImageList: (templateId: string) => void;
  disabled?: boolean;
}

const options = Object.keys(EVENT_TEMPLATE_CONFIG).map((key: string) => ({
  value: key,
  label: get(EVENT_TEMPLATE_CONFIG, `${key}.label`),
  url: get(EVENT_TEMPLATE_CONFIG, `${key}.url`),
}));

const TemplateFormItem = ({ form, unselectBgImage, getBackgroundImageList, disabled }: TypeProps) => {
  const { classes } = useStyle();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [initValue] = useState(
    form.getFieldValue('eventInvitationTemplate')
      ? {
          value: form.getFieldValue('eventInvitationTemplate'),
          label: EVENT_TEMPLATE_CONFIG[form.getFieldValue('eventInvitationTemplate')]?.label || '',
          url: '',
        }
      : null,
  );

  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };

  return (
    <Form.Item
      childStyle={{ marginTop: 16, height: 32 }}
      name="eventInvitationTemplate"
      label={Translation('component.formLabel.template')}
      rules={[DEFAULT_REQUIRED_RULES]}
    >
      {({ value, onChange }) => {
        return (
          <div className={`${classes.d_flex_column} ${classes.w_50}`}>
            <Select
              defaultValue={initValue}
              isSearchable={false}
              isDisabled={disabled}
              className={`w-50`}
              closeMenuOnSelect={true}
              components={{
                Option: ({ ...props }) => (
                  <components.Option {...props}>
                    <div
                      className={`${classes.d_flex_center} ${classes.itemStyle}`}
                      onClick={(e) => {
                        form.setFieldValue('backgroundImage', undefined);
                        unselectBgImage();
                        onChange(props.data.value);
                        getBackgroundImageList(props.data.value);
                      }}
                    >
                      <div className={`${classes.d_flex_center} ${classes.flex_fill}`}>
                        <span className={classes.optionTxt}>{props.data.label}</span>
                      </div>
                      <div className={`${classes.d_flex_center}`}>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(props.data.url, '_blank', 'noopener,noreferrer');
                          }}
                          className={classes.viewTxt}
                        >
                          {Translation('app.button.preview')}
                        </Button>
                      </div>
                    </div>
                  </components.Option>
                ),
                Control: ({ children, ...props }) => (
                  <components.Control {...props}>
                    <div className={`${classes.contentItem}`}>{children}</div>
                  </components.Control>
                ),
              }}
              options={options}
              placeholder={'Please select'}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: 'rgba(0, 0, 0, 0.04)',
                  primary: 'rgba(0, 0, 0, 0.1)',
                },
              })}
            />
          </div>
        );
      }}
    </Form.Item>
  );
};

export default React.memo(TemplateFormItem);
