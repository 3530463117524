import moment from 'moment';
import { EModuleTypes, LibraryPublishedMode } from '../pages/SalesMaterial/constants';
import { LibraryItem } from '../pages/SalesMaterial/types';

export const isSaleModule = (moduleName: string) => {
  return moduleName === EModuleTypes.Sale;
};

export const renderDisplayStatusField = (rowData: LibraryItem): string => {
  if (rowData.publishedAt) {
    if (rowData.publishToSetOnly) {
      return 'component.status.publishedToSetOnly';
    } else {
      return 'component.status.published';
    }
  } else {
    if (rowData.lastPublishTime) {
      return 'component.status.unpublished';
    } else {
      return 'component.status.draft';
    }
  }
};

export const getType = (type: string) => {
  const ref = {
    file: 'File',
    materialSet: 'Material Set',
    content: 'Content',
    link: 'Link',
    text: 'Text',
    picture: 'Picture',
  };
  return ref[type as keyof typeof ref] || '';
};

export const getDefaultDisplayDate = (date: Date, formatKey = 'date') => {
  const defaultFormat = 'DD/MM/YYYY HH:mm';

  return moment(date).format(defaultFormat);
};

export const filterQueryForPublish = (isActiveParam = '', queryPath: string): string => {
  let extendQuery = '';

  if (isActiveParam !== '' && isActiveParam !== LibraryPublishedMode.ALL) {
    // extendQuery = `status_eq=${isActiveParam}&`;
    extendQuery = `filters[status][$eq]=${isActiveParam}&`;
  }

  return extendQuery;
};

const objectsEqualOneLevel = (object1: Record<string, any>, object2: Record<string, any>) =>
  Object.keys(object1).length === Object.keys(object2).length &&
  Object.keys(object1).every((key: string) => object1[key] === object2[key]);

export const arraysEqual = (array1: any[], array2: any[]) =>
  array1.length === array2.length && array1.every((item, index) => objectsEqualOneLevel(item, array2[index]));

export const formatFileName = (str: string) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');

  // Remove diacritical marks
  str = str.replace(/[\u0300-\u036f]/g, '');
  // Replace spaces with underscores
  str = str.replace(/\s+/g, '_');
  // Remove special characters
  str = str.replace(/[^a-zA-Z0-9_]/g, '');

  return str;
};

export const drawQRCodeAndEventInfor = (
  canvas: any,
  context: any,
  qrCodeCanvas: any,
  data: { name: string; eventStartDate?: Date | null; eventEndDate?: Date | null },
) => {
  data.name = data.name.trim();
  data.name = data.name.replace(/\s+/g, ' ');

  // Set canvas size with padding
  const padding = 20;
  const paddingTop = 5;
  const qrCodeSize = qrCodeCanvas.width;
  canvas.width = qrCodeSize + padding * 2;
  let maxWidthLast = canvas.width;

  // set font for event name
  context.font = 'bold 12px Poppins, Helvetica, "sans-serif"';

  // Draw calculate height for canvas base on event name
  const maxWidth = canvas.width - padding * 2 + 5;
  let metrics: any = context.measureText(data.name);
  const lines = [];

  if (metrics.width > maxWidth) {
    let line = '';
    let testLine = '';
    const words = data.name.split(' ');

    for (let i = 0; i < words.length; i++) {
      testLine = line + words[i] + ' ';
      metrics = context.measureText(testLine);

      if (metrics.width > maxWidth && i > 0) {
        metrics = context.measureText(line);
        if (metrics.width > maxWidthLast) {
          maxWidthLast = metrics.width;
        }

        lines.push(line);
        line = words[i] + ' ';
      } else {
        line = testLine;
      }
    }

    metrics = context.measureText(line);
    if (metrics.width > maxWidthLast) {
      maxWidthLast = metrics.width;
    }
    lines.push(line);
  } else {
    lines.push(data.name);
  }

  // set height for canvas
  canvas.height = qrCodeSize + padding * 3 + paddingTop + lines.length * padding;

  if (maxWidthLast > canvas.width) {
    canvas.width = maxWidthLast + padding * 2;
  }

  // Draw background
  context.fillStyle = 'white';
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Draw QR code on canvas
  context.drawImage(qrCodeCanvas, (canvas.width - qrCodeSize) / 2, padding);

  // Add information below QR code
  context.textAlign = 'center';

  // Draw event name
  context.font = 'bold 12px Poppins, Helvetica, "sans-serif"';
  context.fillStyle = 'red';
  lines.forEach((line, index) => {
    context.fillText(line, canvas.width / 2, qrCodeSize + padding * 2 + paddingTop + index * padding);
  });

  // Draw event date
  context.font = '12px Poppins, Helvetica, "sans-serif"';
  context.fillStyle = 'black';
  context.fillText(
    moment(data.eventStartDate).format('HH:mm:ss DD/MM/YYYY') +
      ' - ' +
      moment(data.eventEndDate).format('HH:mm:ss DD/MM/YYYY'),
    canvas.width / 2,
    qrCodeSize + padding * 2 + paddingTop + lines.length * padding,
  );
};
