import React, { RefObject } from 'react';
import { useStyles } from '../styles';
import { useIntl } from 'react-intl';
import Form from '@common/components/Form';
import TextColorSelectBox from '@/app/common/components/TextColorSelectBox';

interface TypeProps {
  initialValue?: string;
  textColorOptions: any[];
  textColorSelectRef: RefObject<HTMLInputElement>;
  disabled?: boolean;
}

const TextColorFormItem = ({ initialValue, textColorOptions, textColorSelectRef, disabled }: TypeProps) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const TEXT_COLOR_VIDATOR = [
    {
      validator(_: any, value: string) {
        if (value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(Translation('component.form-required-text')));
      },
    },
  ];

  return (
    <Form.Item
      childStyle={{ marginTop: 16, alignItems: 'center' }}
      name="textColor"
      label={'Text Color'}
      rules={TEXT_COLOR_VIDATOR}
    >
      {({ value, onChange }) => {
        return (
          <div className={`${classes.d_flex_column} ${classes.w_50}`}>
            <TextColorSelectBox
              ref={textColorSelectRef}
              disabled={disabled}
              options={textColorOptions}
              onSelect={(item) => {
                onChange(item);
              }}
              initialValue={initialValue}
            />
          </div>
        );
      }}
    </Form.Item>
  );
};

export default React.memo(TextColorFormItem);
